/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    header: "GGCS",
    icon: "CoffeeIcon",
    items: [
      {
        url: "/admin/ggcs/buffets/agenda",
        name: "Agenda",
        slug: "ggcs-buffets",
        icon: "CalendarIcon",
      },
      {
        url: "/admin/ggcs/buffets/plates",
        name: "Pratos",
        slug: "ggcs-buffets",
        icon: "CoffeeIcon",
      },
      {
        url: "/admin/ggcs/buffets/orders-today",
        name: "Pedidos do Dia",
        slug: "ggcs-buffets",
        icon: "MenuIcon",
      },
      {
        url: "/admin/ggcs/satisfactions",
        name: "Satisfações",
        slug: "ggcs-satisfactions",
        icon: "ThumbsUpIcon",
      },
      {
        url: "/admin/ggcs/news",
        name: "Notícias",
        slug: "ggcs-news",
        icon: "ColumnsIcon",
      },
      {
        url: "/admin/ggcs/pushes",
        name: "Notificações",
        slug: "ggcs-pushes",
        icon: "BellIcon",
      }
    ]
  }
]
