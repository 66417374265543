<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.displayName }}</p>
      <small>{{activeUserInfo.CPF}}</small>
    </div>

    <feather-icon icon="LogOutIcon" class="con-img ml-10 cursor-pointer " @click="logout"/>


    <!-- <vs-button color="primary" type="border" icon-pack="feather" icon="icon-logout">Sair</vs-button> -->

    <!-- <vs-button icon-pack="feather" class="con-img ml-3 cursor-pointer " icon="icon-logOut"></vs-button>
     -->
    <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>


      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown> -->
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {


      localStorage.clear()

      this.$router.push('/').catch(() => {})
    },
  }
}
</script>
